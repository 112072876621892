import Skeleton from '@components/Common/Skeleton';
import React from 'react';

export const ListingSkeleton = ({ keyIndex, mobileView }) => {
  // Adjust the props accordingly

  return (
    <div key={keyIndex} className='listingSkeleton full-width'>
      <Skeleton
        component='div'
        variant='text'
        height={mobileView ? 220 : 364}
        width={'100%'}
      />
      <Skeleton component='h4' width={'100%'} height={21} variant='text' />
      <Skeleton width={'30%'} height={15} component='p' variant='text' />
      <Skeleton width={'100%'} height={15} component='p' variant='text' />
      <Skeleton width={'100%'} height={70} component='p' variant='text' />
      <Skeleton width={'30%'} height={15} component='p' variant='text' />
      <Skeleton width={'20%'} height={15} component='p' variant='text' />
      <Skeleton width={'20%'} height={15} component='p' variant='text' />
    </div>
  );
};
