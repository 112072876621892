import AOS from 'aos';
import 'aos/dist/aos.css';

const initializeAOS = () => {
  AOS.init({
    offset: 120,
    duration: 400,
    easing: 'ease-in-sine',
    delay: 100,
  });
};

export default initializeAOS;
