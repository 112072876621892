//-------------------DETAILS SECTIONS----------------------
export const CONSTANT_PAGES_URL = {
  home: {
    nameSpace: 'root',
    slug: 'home',
  },
  bookingReview: {
    nameSpace: 'booking',
    slug: 'review-booking-details',
  },
  bookingDetails: {
    nameSpace: 'guest',
    slug: 'booking-detail',
  },
  footer: {
    nameSpace: 'root',
    slug: 'footer',
  },
};

//----------PROPERTY ID FOR ALL COUPON FOR HOME PAGE-------------
export const NULLABLE_KEY = null;

//----------PATHNAMES-------------
export const PATH_NAMES = {
  BOOKING: '/booking',
};

//--------DATEPICKER HEADERS-------
export const DATE_PICKER_HEADERS = {
  checkInDateHeader: 'Select check-in date',
  checkOutDateHeader: 'Select check-out date',
};
