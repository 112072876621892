module.exports = {
  // loagin page content
  progress: {
    loading: 'Loading...',
    checking: 'Checking...',
    priceLoading: '***.00',
  },

  // calender date format
  dateFormat: {
    ddmmyy: 'DD MMM, YYYY',
  },

  // detail page content
  detailContent: {
    villaSnap: 'Villa Snapshot',
    amenities: 'Amenities',
    meals: 'Meals',
    houseRules: 'House Rules',
    spaces: 'Spaces',
    location: 'Location',
    fAQs: 'FAQs',
    Rating: 'Rating',
    callUs: 'Call us on',
    startingFrom: 'Starting from',
    reachUs: 'Reach us',
    contactUs: 'Contact us',
    or: 'Or',
    bookNow: 'Book Now',
    reserveNow: 'Reserve Now at',
    checkAvailability: 'Check Availability',
    maxBookingAmount: {
      totalBookingAmount:
        'Please book your stay offline, since your booking amount',
      exceeding:
        'is greater than 1 Lakh. Contact now to avail 10% real cashback on the day of Check-in.',
    },
    maxBookingNights:
      'Please book your stay offline, since your stay is greater than 30 nights. Contact now to avail 10% real cashback on the day of Check-in',
  },

  priceDetail: {
    villaPrice: 'Villa Price',
    nights: 'Nights',
    totalPayable: 'Total Payable',
    details: 'Details',
    termsContent: {
      byClicking: 'By clicking ‘Pay Now’ button, I agree to the',
      termsAndConditions: 'Terms and Conditions',
      ofElivaas: 'of ELIVAAS.',
    },
    couponDiscount: 'Coupon Discount',
    originalTaxableAmount: 'Original Taxable Amount',
  },
  // booking info page page content
  bookingInfo: {
    maxNight: 30,
    maxDayError:
      'Planning a stay over 30 nights? Kindly reach out to us directly.',
    maxPriceError: {
      yourTotal: 'Your total booking amount',
      isExceeding:
        'For high-value bookings(> INR 5L), please contact us directly for exclusive offers. ',
    },
    payNow: 'Pay Now',
    reviewBooking: 'Review Booking Details',
    bookingFor: {
      booking: 'Booking for',
      nights: ' Nights',
    },
    modify: 'Modify',
    guestDetails: 'Primary Guest Details',
    note: {
      note: '*Note',
      noteText:
        '- All communication for this booking will be sent to the above details. Also, name should be as per official govt. ID',
    },
    policyRules: 'Policy & Rules',
  },

  bookingInfoForm: {
    name: 'Name',
    mr: 'Mr.',
    mrs: 'Mrs.',
    ms: 'Ms.',
    firstName: 'First Name',
    lastName: 'Last Name',
    enterEmail: 'Enter email id',
    enterMobile: 'Enter 10-digit mobile number',
    labelName: {
      first_name: 'first_name',
      last_name: 'last_name',
      email: 'email',
      mobile: 'mobile',
    },
  },

  paymentField: {
    paymentFailed: 'Oh no, your payment failed!',
    differentpayment: 'Please use different payment mode or try again later.',
    tryAgain: 'Try Again',
  },

  bookingConfirm: {
    bookingConfirmed: 'Booking Confirmed',
    yourBooking: 'Your booking is confirmed!',
    contactUs: 'Contact Us',
    goHome: 'Go to Home',
    getDirections: 'Get Directions',
    faq: 'FAQs',
    support: 'Support',
    sentConfirmation: {
      confirmation: 'We’ve sent the confirmation to',
      and: 'and',
    },
    bookedForNights: {
      booked: 'Booked for ',
      nights: 'Nights',
    },
    label: {
      checkIn: 'Check-In',
      checkOut: 'Check-Out',
      guests: 'No. of Guests',
      bookingID: 'Booking ID',
    },
  },

  // catalog page page content
  catalog: {
    title: 'Popular Search',
  },

  price: {
    maxpaymentLimit: 500000,
  },

  contact: {
    number: '+91 79 6946 9950',
    whatsApp: '+919311796888',
    email: 'info@elivaas.com',
    whatsAppText: 'Please assist me to book a luxurious stay with ELIVAAS.',
  },

  cta: {},
};
