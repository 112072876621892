import { errorImage } from '@reducers/globalReducers';
import { contact } from '@utils/customText';
import dayjs from 'dayjs';

export const isValidEmail = (email) => {
  // Regular expression for basic email validation
  const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailPattern.test(email);
};

export const isValidMobileNumber = (mobileNumber) => {
  // Remove spaces and non-digit characters
  const mobileNumberWithoutSpaces = mobileNumber.replace(/\D/g, '');

  // Check if the mobile number is exactly 10 digits long
  return (
    mobileNumberWithoutSpaces.length === 10 &&
    !/^[0-5]/.test(mobileNumberWithoutSpaces)
  );
};

export const scrollToTop = () => {
  const topElement = document.getElementById('headerTop'); // Replace 'top' with the ID of your top element
  if (topElement) {
    const timer = setTimeout(() => {
      topElement.scrollIntoView({ behavior: 'smooth' });
    }, 1000); // Auto close after 3 seconds
  }
};

export const updateCalenderList = async (
  startDate,
  endDate,
  availabilityCalenderList,
  copyAvailabilityCalenderList
) => {
  return new Promise((resolve) => {
    if (
      dayjs(startDate).format('YYYY-MM-DD') ===
      dayjs(endDate).format('YYYY-MM-DD')
    ) {
      availabilityCalenderList = copyAvailabilityCalenderList;
      const dates = availabilityCalenderList
        ? Object.keys(availabilityCalenderList)
        : [];

      let foundStartDate = '';
      let findFirstZero = '';

      for (let i = 0; i < dates.length; i++) {
        if (dates[i] === dayjs(startDate).format('YYYY-MM-DD')) {
          foundStartDate = dates[i];
        }

        if (foundStartDate && availabilityCalenderList[dates[i]] === 0) {
          findFirstZero = dates[i];
          break; // Stop when the first zero is found
        }
      }

      // Update availabilityCalenderList based on findFirstZero
      let found = false;
      for (let i = 0; i < dates.length; i++) {
        if (found) {
          availabilityCalenderList[dates[i]] = 0; // Set availability to 0 for all subsequent dates
        } else if (dates[i] === findFirstZero) {
          found = true;
          availabilityCalenderList[dates[i]] = 1; // Set availability to 1 for the first date with availability 0
        }
      }
      resolve(availabilityCalenderList);
    }
  });
};

export const changeCheckoutValue = (calendarList, checkoutDate) => {
  const dates = Object.keys(calendarList);
  for (let i = 0; i < dates.length; i++) {
    if (dates[i] === checkoutDate && calendarList[dates[i]] === 0) {
      calendarList[dates[i]] = 1;
    }
  }
  return calendarList;
  //return Promise.resolve(calendarList);
};

export const pricingRefactored = (data) => {
  if (data && data.length > 0) {
    return data.find((term) => term.is_default === true) || null;
  }
  return null;
};

export const commonImageAvatar = (name) => {
  return {
    children: `${name?.split(' ')[0][0]?.toUpperCase()}`,
  };
};

export const windowReload = async () => {
  return new Promise((resolve) => {
    window.location.reload();
    resolve();
  });
};

//----------------CHANGE UPPER CASE DATA TO LOWER CASE------------------
export const changeToLowerCase = (data) =>
  typeof data === 'string'
    ? data.toLowerCase()
    : Array.isArray(data)
    ? data.map(changeToLowerCase)
    : typeof data === 'object' && data !== null
    ? Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          changeToLowerCase(value),
        ])
      )
    : data;

// ----------------------CUSTOM ERROR MESSAGE--------------------
export const handleErrorResponse = (error, dispatch, customErrorMessage) => {
  if (error?.response?.status === 401) {
    return '';
  } else {
    const errorMessage =
      customErrorMessage || error?.message || 'An error occurred';
    dispatch(errorImage(true, errorMessage));
    console.error('Error:', error);
  }
};

export const recursivelyConvertToNull = (obj) => {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];
      if (typeof value === 'object' && value !== null) {
        obj[key] = recursivelyConvertToNull(value);
      } else if (
        value === '' ||
        (key.includes('_date') && !dayjs(value).isValid())
      ) {
        obj[key] = null;
      }
    }
  }
  return obj;
};

//----------------------USER AND PROFILE DATA UPDATE FUNCTION----------------------
export const userData = (formData, profileImage) => {
  return {
    gms_user: {
      gender: formData?.gender,
      birth_date: dayjs(formData?.birthday).format('YYYY-MM-DD'),
      anniversary_date: dayjs(formData?.anniversary).format('YYYY-MM-DD'),
      marital_status: formData?.maritalStatus,
      user: {
        salutation: 'Mr.',
        first_name: formData?.firstName,
        last_name: formData?.lastName,
        email: formData?.email,
        mobile: formData?.mobile,
        profile_photo_url: profileImage?.profile_photo_url,
      },
    },
    address: {
      street_address: formData?.address,
      postal_code: formData?.pincode?.toString(),
      city: formData?.city,
      state: formData?.state,
      country: 'India',
    },
  };
};

//----------------------USER AND PROFILE DETAILS FUNCTION----------------------
export const userDetails = (profileDetails) => {
  if (!profileDetails) {
    return {}; // Handle the case where profileDetails is null or undefined
  }

  const {
    User: {
      first_name,
      last_name,
      mobile,
      email,
      profile_photo_url_signed,
    } = {},
    Address,
    birth_date,
    anniversary_date,
    gender,
    marital_status,
    id,
  } = profileDetails;

  const { street_address, postal_code, city, state } = Address || {};

  return {
    firstName: first_name || '',
    lastName: last_name || '',
    mobile: mobile || '',
    email: changeToLowerCase(email) || '',
    address: street_address || '',
    pincode: parseInt(postal_code) || '',
    city: city || '',
    state: state || '',
    birthday: dayjs(birth_date)?.toDate() || null,
    anniversary: dayjs(anniversary_date)?.toDate() || null,
    gender: gender || '',
    maritalStatus: marital_status || '',
    state: state || '',
    image: profile_photo_url_signed || null,
    guest_id: id,
  };
};

// Helper function to convert an array to an object using a specified key
export const convertArrayToObject = (array, key) => {
  return array?.reduce((obj, item) => {
    obj[item[key]] = item;
    return obj;
  }, {});
};

// Helper function to Transform Image and Mobile Image data
export const transformImageData = (data) => {
  return (
    data?.map((image, index) => ({
      id: index + 1,
      title: image.name,
      image: image.url,
      mImage: image?.image_mobile_url ? image.image_mobile_url : image.url,
    })) ?? []
  );
};

//Helper function to Transform Data and Return Length / Return Merged Array
export const mergedDataAndReturnLengthOrArray = (data, returnType) => {
  if (!data || typeof data !== 'object') {
    console.error('Invalid data or format');
    return returnType === 'length' ? 0 : [];
  }
  const mergedArray = Object.values(data).filter(Array.isArray).flat();
  if (returnType === 'array') {
    return mergedArray;
  } else if (returnType === 'length') {
    return mergedArray.length;
  }
};
export const getDateRange = (startDate, endDate) => {
  let dates = [];
  let currentDate = new Date(startDate);
  endDate = new Date(endDate);

  currentDate.setHours(0, 0, 0, 0);
  endDate.setHours(0, 0, 0, 0);

  while (currentDate < endDate) {
    dates.push(new Date(currentDate));
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dates;
};

export const getDisabledDates = (data, selectDate, startDate) => {
  const disabledDates = getDateRange(startDate, selectDate[0].startDate);
  const additionalDisabledDates = data
    .filter((item) => item.availability === 0)
    .map((item) => item.date);

  return [...disabledDates, ...additionalDisabledDates];
};

//----------------------PAYMENT TERM ID SWITCH CASE -----------------------
export const isPropertyPaymentTermId = (pricing) => {
  return pricing?.ProformaInvoice?.property_payment_term_id !== null;
};

//----------------------SAVE FIRST NAME / LAST NAME IN LOCAL STORAGE -----------------------
export const saveUserDetailsToLocalStorage = (data) => {
  const { first_name, last_name, mobile } = data?.User ?? {};
  if (first_name === null && last_name === null) {
    localStorage.setItem('mobile', mobile ?? '');
  } else {
    localStorage.setItem('firstname', first_name ?? '');
    localStorage.setItem('lastname', last_name ?? '');
    localStorage.removeItem('mobile');
  }
};

//----------------Format Query Parmas For Searching Properties--------------------------->
export const formatQueryParam = (key, value) =>
  value ? `${key}=${value}` : '';

//--------------------Saving filter Values for persisting the filter values --------- >
export const saveFilterValues = (filterValues) => {
  localStorage.setItem('filterValues', JSON.stringify(filterValues));
};

export const capitalizeFirstLetter = (str) => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};
export const getCityNameFromPath = (pathname) => {
  if (!pathname) return '';
  const cityNamePath = pathname.split('villas-in-')[1];
  if (!cityNamePath) return '';
  return cityNamePath.split('?')[0].replace(/-/g, ' ').toLowerCase();
};
// ------------------Calculate Minimum Property Stays ----------------

export const calculateMinimumStay = (
  checkInDate,
  checkOutDate,
  minimumStay
) => {
  if (checkInDate && checkOutDate && minimumStay) {
    const startDate = dayjs(checkInDate, 'DD MMM, YYYY');
    const endDate = dayjs(checkOutDate, 'DD MMM, YYYY');
    const diffInDays = endDate.diff(startDate, 'day');
    const formattedDate = startDate.format('YYYY-MM-DD');
    const selectedDateMinDays = minimumStay[formattedDate] ?? 1;
    if (
      minimumStay.hasOwnProperty(formattedDate) &&
      diffInDays < selectedDateMinDays
    ) {
      return selectedDateMinDays;
    }
  }
  return 1;
};

export const comparePropertyParams = (obj) => {
  const values = localStorage.getItem('filterValues');
  const parsedFilterValues = JSON.parse(values) || {};
  let valuesDiffer = false;
  Object.keys(obj).forEach((prop) => {
    if (parsedFilterValues[prop] !== obj[prop]) {
      parsedFilterValues[prop] = obj[prop];
      valuesDiffer = true;
    }
  });

  return valuesDiffer;
};

export const getFilterBarValues = (paramValues, valuesDiffer, cityData) => {
  const values = localStorage.getItem('filterValues');
  const parsedFilterValues = JSON.parse(values) || {};
  const isValid = isCityNameValid(cityData, paramValues.selectedCity);
  const valuesObj = {
    city: getValidCityName(paramValues.selectedCity, isValid),
    checkInDate: valuesDiffer
      ? paramValues.checkInDate
      : parsedFilterValues.checkInDate,
    checkOutDate: valuesDiffer
      ? paramValues.checkOutDate
      : parsedFilterValues.checkOutDate,
    numberOfGuests: valuesDiffer
      ? paramValues.numberOfGuests
      : parsedFilterValues.numberOfGuests,
    numberOfChildren: valuesDiffer
      ? paramValues.numberOfChildren
      : parsedFilterValues.numberOfChildren,
  };
  return valuesObj;
};

export const isCityNameValid = (cities, city) => {
  if (cities) {
    return cities.includes(city);
  }
};

export const getValidCityName = (name, isValid) => {
  if (isValid) {
    return name;
  } else {
    const values = localStorage.getItem('filterValues');
    const parsedFilterValues = JSON.parse(values) || {};
    return parsedFilterValues?.selectedCity;
  }
};

// Function to parse URL query parameters
export const getParameterByName = (name, url) => {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

// utils/whatsapp.js
export const redirectToWhatsApp = (utmCampaign, utmSource) => {
  let message = '';
  // Set WhatsApp text based on utm_source and prepend utm_campaign

  if (utmSource === 'fb' || utmSource === 'ig') {
    message = `Hey, ${contact?.whatsAppText}`;
  } else if (utmSource === 'google' || utmSource === 'adwords') {
    message = `Hello, ${contact?.whatsAppText}`;
  } else {
    message = `Hey there, ${contact?.whatsAppText}`;
  }

  const encodedMessage = encodeURIComponent(message);
  window.open(
    `https://wa.me/${contact.whatsApp}?text=${encodedMessage}`,
    '_blank'
  );
};
export const getCouponType = (isCustomCoupon, isDoubleDip) => {
  switch (true) {
    case !isCustomCoupon && !isDoubleDip:
      return 'SiteWide';

    case isCustomCoupon && !isDoubleDip:
      return 'CustomCoupon';

    case isCustomCoupon && isDoubleDip:
      return 'DoubleDipCoupon';

    default:
      return '';
  }
};

export const capitalizeWords = (str) => {
  if (!str) return '';
  return str
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};
