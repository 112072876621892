import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useMediaQuery, useTheme } from '@mui/material';

const LazyImage = ({
  src,
  alt,
  effect,
  width,
  height,
  className,
  defaultImage,
  quality,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleImageError = (event) => {
    event.target.src = defaultImage; // Set the default image URL on image error
  };

  const getOptimizedImageUrl = (
    originalUrl,
    width,
    quality,
    format = 'webp'
  ) => {
    const baseUrl = 'https://ivtpl.in/';

    // Conditionally build the URL based on whether width is passed
    const cdnPath = width
      ? `cdn-cgi/image/width=${width},quality=${
          quality ? quality : '80'
        },format=webp/`
      : `cdn-cgi/image/quality=${quality},format=${format}/`;

    let optimizedUrl = `${baseUrl}${cdnPath}${originalUrl}`;
    console.log('Optimized Image URL: ', optimizedUrl);
    return optimizedUrl;
  };

  // Determine the optimized image URL based on the device type
  //   const optimizedSrc = isMobile
  //     ? getOptimizedImageUrl(src, 400) // Use width for mobile
  //     : getOptimizedImageUrl(src, undefined, 'auto', 'avif'); // Only change format for non-mobile
  const optimizedSrc = getOptimizedImageUrl(src, width, quality, 'avif'); // Only change format for non-mobile

  return (
    <>
      <LazyLoadImage
        alt={alt}
        effect={effect ? effect : 'blur'}
        src={optimizedSrc} // Use the optimized src
        width={width ? width : 'auto'} // Keep width if passed
        height={height ? height : 'auto'} // Keep height if passed
        wrapperClassName={className ? className : 'full-width'}
        onError={handleImageError}
        visibleByDefault={src === defaultImage}
        quality={quality}
      />
    </>
  );
};

export default LazyImage;
